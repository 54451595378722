.nav {
  writing-mode: vertical-rl;
  display: inline-flex;
  justify-content: space-evenly;
  padding: 1rem;
  width: 5rem;
  height: calc(100vh - 2rem);
  align-items: center;
  position: fixed;
  left: 1rem;
  top: 1rem;
  bottom: 1rem;

  @media only screen and (max-width: 1000px){
    writing-mode: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 2;
  
    &.show {
      background-color:#EF476F;
      width: calc(100vw - 2rem);
      backface-visibility: hidden;
      animation: slide 300ms ease-in-out forwards;
    }
    

  }

  

  a {
    transition: all 400ms ease-out;
    font-size: 1.6rem;
    color: #f9f9f9;
    @media only screen and (max-width: 1000px){
      margin: 1rem 0;
      font-size: 2.8rem;
      opacity: 0; 
		  visibility: visible;
		  transform: translate3d(0,0,0) scale(1);
		  transition: transform 0.3s, visibility 100ms 0s;

       &.is-shown:nth-child(1) {
         animation: slideDown 200ms 500ms forwards;
       }
       &.is-shown:nth-child(2) {
        animation: slideDown 400ms 700ms forwards;
      }
      &.is-shown:nth-child(3) {
        animation: slideDown 600ms 900ms forwards;
      }
    }
  }
  a:hover {
    transform: scale(1.5);
  }

  
}

.activeLink {
  color:#E94957!important;
  font-weight: bold;
  transform: scale(1.6);
  @media only screen and (max-width: 1000px){
    color: #FFEBEB!important;
    text-shadow: 1px 2px rgba(0,0,0,0.3);
    font-size: 3rem !important;
  }
}

.mobile--nav{
  @media only screen and (min-width: 1000px){
    display: none;
  }
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #F9f9f9;
    box-shadow: 1px 2px 5px rgba(0,0,0,0.3);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    z-index: 3;
    transition: 300ms ease-out;
  span {
  display: flex;
  justify-content: flex-end;
  width: 3.3rem;
  height: 2px;
  background: #333;
  position: relative;
  border-radius: 5px;
    @media only screen and (min-width: 1000px){
      display: none;
    }
  
  &:before, 
  &:after {
    position: absolute;
    width: 3.3rem;
    height: 2px;
    background: #333;
    border-radius: 3px;
  }

   &:before {
    content: '';
    top: -8px;
    
   }
   &:after {
    content: '';
    bottom: -8px;
   }
  }
}

@keyframes slide {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.mobile--nav:hover {
	transform: scale(.9);
}

// .mobile--nav:hover span::after {
// 	bottom: -9px;
// }


.mobile--nav-span.menu-is-open {
	background: transparent;
}

.mobile--nav-span.menu-is-open::before {
	top: 0;
	transform: rotate(-45deg);
	background: #333;
}
.mobile--nav-span.menu-is-open::after {
	top: 0;
	transform: rotate(45deg);
	background: #333;
}
.mobile--nav-span.menu-is-open::before,
.mobile--nav-span.menu-is-open::after {
	transition: all 400ms ease-in-out;
}

.mobile--nav-span.menu-is-open:hover  {
	transition: all 200ms ease-in-out;
	transform: scale(.85);
}

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translate3d(0,-50px,0);
	}
	50%{
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: translate3d(0,0,0);
	}
}