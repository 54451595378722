.projects {
  &--wrapper {
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F2C354;
    border-radius: 10px;
  
  }

  &--container {
    display: grid;
    grid-template-rows: 15vh 75vh 7vh;
    grid-template-columns: auto;
    // height: 95vh;
    // width: 95vw;
  }
  &--text {
    grid-row: 1;

    grid-column: 1 /-1;
    justify-self: center;
    align-self: center;
    font-size: 7vw;
    color: #FCF2D9;
    text-shadow: 1px 3px rgba(0,0,0,0.4);

     span {
      font-family: 'Londrina Solid', cursive;
      font-size: 4rem;
     }
  }
&--grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: dense;
  grid-gap: 6px;
  height: 70vh;
  width: 70vw;
  grid-row: 2;
  grid-column: 1;
  justify-self: center;
  align-self: center;
}
  &--sample {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    background-color: #333;
    background-position: top;
    object-fit: contain; 
    cursor: pointer;
    transition: all 300ms linear;
    display: flex;

     a {
       width: 100%;
       height: 100%;
       opacity: 0;
       background: rgba(0,0,0,0.7);
      color: #f9f9f9;
      text-shadow: 1px 1px rgba(0,0,0,0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      border-radius: 5px;
      transition: all 300ms linear;
      font-family: 'Londrina Solid', cursive;
      text-align: center;

        &:hover {
          opacity: 1;
        }
     }
    
    &:hover {
      transform: scale(.9);
      box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
    }
  }
  & .project-1 {
    grid-column: 1;
    grid-row: 1;
  }
  & .project-2 {
    grid-column: 1;
    grid-row: 2;
  }
  & .project-3 {
    grid-column: 2 / span 2;
    grid-row: 1 / span 2;;
  }
  & .project-4 {
    grid-column: 1 / span 2;
    grid-row: 3 / span 2;
  }
  & .project-5 {
    grid-column: 3;
    grid-row: 3;
  }
  & .project-6 {
    grid-column: 3;
    grid-row: 4;
  }
}



