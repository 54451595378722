*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  background-color: #f9f9f9;
}
#root {
  height: calc(100vh - 2rem);
  width: calc(100vw - 2rem);
  margin: 1rem;
  border-radius: 10px;
}
.App {
  position: relative;
}
a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

h1, h2, h3, h4, h5 {
// font-family: 'BenchNine', sans-serif;
// font-family: 'Fira Sans Extra Condensed', sans-serif;
// font-family: 'Francois One', sans-serif;
font-family: 'Londrina Solid', cursive;
// font-family: 'Open Sans Condensed', sans-serif;
// font-family: 'Pathway Gothic One', sans-serif;
// font-family: 'Pragati Narrow', sans-serif;
// font-family: 'Rambla', sans-serif;
// font-family: 'Scada', sans-serif;
}
p {
  // font-family: 'BenchNine', sans-serif;
  // font-family: 'Fira Sans Extra Condensed', sans-serif;
  // font-family: 'Francois One', sans-serif;
  // font-family: 'Londrina Solid', cursive;
  font-family: 'Open Sans Condensed', sans-serif;
  // font-family: 'Pathway Gothic One', sans-serif;
  // font-family: 'Pragati Narrow', sans-serif;
  // font-family: 'Rambla', sans-serif;
  // font-family: 'Scada', sans-serif;
}

button, a, span {
  // font-family: 'BenchNine', sans-serif;
  // font-family: 'Fira Sans Extra Condensed', sans-serif;
  // font-family: 'Francois One', sans-serif;
  // font-family: 'Londrina Solid', cursive;
  // font-family: 'Open Sans Condensed', sans-serif;
  // font-family: 'Pathway Gothic One', sans-serif;
  // font-family: 'Pragati Narrow', sans-serif;
  // font-family: 'Rambla', sans-serif;
  font-family: 'Scada', sans-serif;
}

.linkOut {
  width: 100%;
  height: 100%;
  display: flex;
}

.linkOut.email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-out;
  &:hover >  p  {
    transform: scale(1.3)
  }
}

// @mixin respond($breakpoint){
//   @if $breakpoint == phone {
//     @media only screen and (max-width: 37.5em) { @content };    //600px
//   }
//   @if $breakpoint == tab-port {
//     @media only screen and (max-width: 56.25em) { @content };     //900px
//   }
//   @if $breakpoint == tab-land {
//     @media only screen and (max-width: 75em) { @content };    //1200px
//   }
//   @if $breakpoint == big-desktop {
//     @media only screen and (min-width: 112.5em) { @content };    //1800
//   }
// }