//responsive import
@import '../mixin.scss';

.about {
   width: calc(100vw - 2rem);
   height: calc(100vh - 2rem);
   display: flex;
   justify-items: center;
   align-items: center;
   background: #48CAE4;
  border-radius: 10px;
  &--container {
    margin: 0 auto;
    width: 90vw;
    height: 90vh;;
    display: grid;
    grid-template-columns: 5rem repeat(6, 1fr) 5rem;
    grid-template-rows: auto;
    @include respond(phone){
      grid-template-rows: 30vh auto;
    }
    @media only screen and (min-width: 601px) and (max-width: 1000px){
      grid-template-columns: 1rem repeat(6, 1fr) 1rem;
      width: 98vw;
      height: 98vh;
    }
  }

  &--side.profile--photo {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    grid-column: 2 / span 3;
    grid-row: 3 / span 3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include respond(phone){
      grid-column: 1 / -1;
      grid-row: 1;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      // background-position: top;
    }
  }

  &--side.profile--info {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    grid-column: 5 / span 3;
    grid-row: 3 / span 3;
    background-color: #f9f9f9;
    display: grid;
    grid-template-rows: auto;
    @include respond(phone){
      grid-column: 1 / -1;
      grid-row: 2;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding: 0 0 1.5rem 0;
    }

     h3 {
      align-self: end;
      justify-self: center;
      font-size: 9vw;
      grid-row: 1;
      text-shadow: 1px 3px rgba(0,0,0,0.3);
      color: #9CEAEF;
      @include respond(phone){
        grid-row: 1;
      }
     }

     p {
       font-size: 1.8rem;
       line-height: 1.8;
       padding: 2rem;
       text-align: left;
       grid-row: 2;
       @include respond(phone){
        line-height: 1.8;
        padding: 1rem 2rem;
        font-size: 1.6rem;
       }
       @media only screen and (min-width: 601px) and (max-width: 1000px){
         font-size: 1.7rem;
         padding: 1.5rem 2rem;
       }
     }
  }

  &--side.profile--skills {
    grid-row: 3;
     p {
       text-align: center;
       font-weight: bold;
     }
     @include respond(phone){
       grid-row: 3;
     }
  }
  &--contact {
    grid-row: 7;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    margin: 0 auto;
    align-self: center;
    justify-self: center;
    @include respond(phone){
      grid-column: 1 / -1;
      display: none;
    }
 
  }
  &--contactContainer {
    display: flex;
    grid-column: 1 / -1;
    justify-self: center;
    @include respond(phone){
      padding-top: 1rem;
    }
  }
  &--header {
    grid-row: 1;
    grid-column: 1 / -1;
    justify-self: center;
    align-self: center;
  }
}
.skills {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background: #f9f9f9;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
}
.svg {
  background-size: cover;
  background-position: center;
  height: 25px;
  width: 25px;
  margin: 0 3px;
}

.about--contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10rem;
  cursor: pointer;
  @include respond(phone){
    height: 100%;
    width: 8rem;
  }
  

 &:hover > .contact-p{
  opacity: 1;
  
 }

}
.contact-info {
  background: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.contact-p {
  font-size: 2rem;
  margin: 10px;
  color: #f9f9f9;
  opacity: 0;
  transition: opacity 300ms linear;

   &.resume a{ 
     color: #f9f9f9;
     font-family: 'Open Sans Condensed', sans-serif;
     font-size: 2rem;
    
    }
  @include respond(phone){
    display: none;
  }
}

.about--text {
  font-size: 2.8rem;
  padding: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #f9f9f9;
  position: relative;
  @include respond(phone){
    font-size: 1.8rem;
    margin-bottom: 0;
  }

   &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    height: 2px;
    background: #f9f9f9;
    width: 100%;
    left: 0;
    margin: 0 auto;
    @include respond(phone){
      bottom: 6px;
    }
   }
}

.emailLink {
  width: 25px !important;
  height: 25px !important;
  color: #333;
}