.main {
  display: grid;
  grid-template-columns: 10rem repeat(5, 1fr) 10rem;
  grid-template-rows: 10rem repeat(5, 1fr) 10rem;
  width: calc(100vw - 2rem);
  height: calc(100vh - 2rem);
  background: #5FAD56;
  border-radius: 10px;

   &--container {
     grid-column: 2 / span 5;
     grid-row: 2 / span 5;
     justify-self: center;
     align-self: center;

      p {
        color: #F2C14E;
        font-weight: bold;
        
      }
   }

   &--intro {
    font-size: 2.8rem;
   }

   &--name {
    font-size: 15rem;
    padding-left: 1rem;
    color: #CBE4C8;
    text-shadow: 1px 3px rgba(0, 0, 0, 0.4);
    @media only screen and(max-width: 400px){
      font-size: 11rem;
    }
   }

   &--subheading {
    font-size: 2rem;
    padding-left: 9rem;
   }

   &--contactInfo {
    grid-row: 7;
    grid-column: 1 / -1;
    display: flex;
    height: 100px;
    // width: 40%;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
   }

   &--contact {
     cursor: pointer;
     background: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 1rem;
    box-shadow: 2px 3px 5px rgba(0,0,0,0.3);
      &:nth-child(2){
         svg {
           height: 30px;
           width: 30px;
         }
      }
      p {
        transition: all 300ms ease-out;
        // color: #CBE4C8;
        color: #333;
      }
      &:hover >  p  {
        transform: scale(1.3)
      }
     
   }
}

 