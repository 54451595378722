.project {
  width: calc(100vw - 2rem);
  height: calc(100vh - 2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F78154;
  border-radius: 10px;

   &--container {
    display: grid;
    //grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
    grid-template-columns: 5rem repeat(5, 1fr) 5rem;
    grid-auto-rows: minmax(10rem, auto);
    // grid-auto-rows: auto;
    // // grid-template-rows: 5rem repeat(5, 1fr) 5rem;
    width: 90vw;
    height: 90vh;
   }
     &--image {
      grid-row: 2 / span 5;
      grid-column: 2 / span 3;
      object-fit: contain;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f9f9f9;
      border-radius: 10px;
      @media only screen and (max-width:750px){
        grid-column: 1 / -1;
        grid-row: 1 / span 3;
      }
    

       &--img {
        max-width: 45vw;
        max-height: 80vh;
        box-shadow: 3px 3px 10px rgba(0,0,0,0.3);
        @media only screen and (max-width:750px){
          max-width: 80vw;
          max-height: 37vh;
        }
       }
     }

     &--details {
      grid-column: 5 / span 2;
      grid-row: 2 / span 5;
      padding-left: 3rem;
      @media only screen and (max-width:750px){
        grid-column: 1 / -1;
        grid-row: 4 / span 3;
        padding: 1rem;
      }
     }
     &--name {
      font-size: 7vw;
      font-weight: bold;
      color: #FEF1EC;
      text-shadow: 1px 3px rgba(0,0,0,0.3);
      @media only screen and (max-width:750px){
        padding: 1rem;
      }
     }
     &--description {
      justify-self: center;
      align-self: end;
      font-size: 2rem;
      line-height: 1.6;
      margin-bottom: .5rem;
      padding-left: 1rem;
      color: #FCD4C5;
      font-weight: bold;

       span {
         font-weight: bold;
         font-size: 1.6rem;
         color: #fff;
       }

     }
     
   

     &--links {
       display: flex;
       align-items: center;
       padding: 0 1rem;

       
     }
   
     
   }

   .link {
    margin-bottom: 0;
  }

  .siteLink {
    font-size: 1.6rem;
    color: #333;
    padding-left: 5px;
    transition: all 200ms ease-out;
    padding: 3px;
    background: linear-gradient(to bottom,
    rgba(247, 188, 141, 0)0%,
    rgba(247, 188, 141, 0)50%,
    rgba(247, 188, 141, 1)50%,
    rgba(247, 188, 141, 1)100%);
    background-repeat: no-repeat;
    background-size: 100% 200%;

    &:hover {
      background-position: 0 100%;
      color: #fff;
      
    }
    
    
  }

  .back {
    grid-row: 7;
    grid-column: 7;
    justify-self: center;
    align-self: center;
    font-size: 2rem;
    color: #333;
    transition: all 300ms ease-out;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f9f9f9;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    // @media only screen and (max-width:750px){
    //   grid-column: 1;
    // }

     &:hover {
       transform: scale(1.2);
     }
  }

  .project--github-logo {
    width: 30px;
    height: 30px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    border-radius: 50%;
    box-shadow: 1px 2px 5px rgba(0,0,0,0.3);
    transition: all 300ms linear;

     &:hover {
       transform: scale(1.2);
     }
  }
.margin-left {
  margin-left: 2rem;
}